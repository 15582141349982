* {
  box-sizing: border-box;
}
html{
  width: 100vw;
  overflow-x: hidden;
}
body {
  width: 100vw;
  margin: auto;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: "Nunito Sans", sans-serif !important;
  width: 100vw;
    max-width: 1600px;
}
.nunito-sans-body{
  font-family: "Nunito Sans", sans-serif;
}
.header {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(219, 20, 3, 1);
  border-bottom: 1px solid rgba(45, 45, 66, 0.1);
  padding: 5px;
}
.header i {
  font-size: 20px;
  margin: 5px;
}
.header span i {
  color: #3224e9;
}
.header a {
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
}
.navbar-header {
  position: sticky;
  top: -2px;
  left: 0px;
  background-color: white;
  z-index: 99;
}
.navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  text-align: center;
  margin: 10px 0px;
}
.navbar-logo {
  width: 20%;
}
.navbar-logo img {
  width: 80%;
}
.navbar-center {
  width: 60%;
}
.navbar-center > ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
}
.navbar-center ul li {
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 2s;
}
.hLink {
  color: black;
  text-decoration: none;

}
.btnGet{
  display: flex;
  justify-content: left;
  height: 8vh;

  
}
.thanks{
  position:absolute;
  top:48%;
  left:40%
}

.getTxtLive{
 font-size: 18px;
 border-radius: 5px;
 color: white;
 text-decoration: none;
}
.getTxtLive:hover{
  color: black;
}
.highlight-blue {
  border-radius: 1em 0 1em 0;
  background-image: linear-gradient(
    -100deg,
    rgba(0, 20, 255, 0.2),
    #146cba 95%,
    #6a8398
  );
  color: white;
  width: 30%;
}
.highlight-bluewhat{
  border-radius: 1em 0 1em 0;
  background-image: linear-gradient(
    -100deg,
    rgba(0, 20, 255, 0.2),
    #146cba 95%,
    #6a8398
  );
  color: white;
  width: 50%;
}
.popDown {
  list-style: none;
}
.navbar-center > ul > li:hover {
  text-decoration: underline;
  text-decoration-color: blue;
}
.Popup {
  position: fixed;
  top: 130px;
  left: 600px;
  width: 300px;
  color: white;
  transition: 1s ease-in-out;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}
.dpopup {
  position: fixed;
  top: 100px;
  left: 0px;
  width: 300px;
  color: white;
  transition: 1s ease-in-out;
  visibility: visible;
  opacity: 1;
  z-index: 1;
}
.navbar-center > ul > .gaming:hover .Popup {
  opacity: 1;
  width: 400px;
  z-index: 1;
  visibility: visible;
}
.navbar-center > ul > .service:hover .Popup {
  opacity: 1 !important;
  width: 400px !important;
  z-index: 1 !important;
  visibility : visible !important;
}
.Popup > ul {
  list-style: none;
  background-color: white;
}
.Popup > ul > li {
  border: none;
  outline: none;
  border-bottom: 0.1px solid gray;
  font-size: 18px;
  margin-bottom: 5px;
}
.activeBar{
  font-size: 30px;
  background-color: white;
  color: black;
  position: fixed;
  top: -100%;
  right: -200px;
  display: none;
  visibility: hidden;
  opacity: -1;
  transition: 5s;
}
.dactive{
  display: block;
  visibility: visible;
  opacity: 1;
  top: 128px;
  right: 16px;
}

.navbar-pop {
  position: fixed;
  top: 0px;
  right: 0px;
  display: none;
  width: 0px;
  height: 0px;
}
.navbar-right {
  width: 20%;
}
.navbar-right button {
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 1px;
  padding: 11px 20px 11px;
  outline: 0;
  position: relative;
  background-color: rgba(253, 34, 106, 1);
  border: none;
}

/* .navbar-right button:after {
  content: "";
  background-color:rgba(253, 34, 106, 1);
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 7px;
  left: 7px;
  transition: 0.2s;
} */

.navbar-right button:hover:after {
  top: 0px;
  left: 0px;
}
.navbar-right span {
  margin: 15px;
}
.bg-img {
  width: 98%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(./image/it_agency_slider_1.webp);
  background-position: center center;
  background-size: cover;
  box-sizing: border-box;
}
.img-contant{
  padding-top: 170px;
  margin-left: 220px;

}
.img-contant h1 {
  color: white;
  font-size: 32px;
 
}
.img-contant h5 {
  color: white;
  font-size:17px;
 
}
.img-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}
.img-btn button:nth-child(1) {
  background-color: rgba(255, 105, 0, 1);
  font-size: 22px;
  padding: 10px 15px;
  margin: 5px;
  border-radius: 10px;
  border: none;
  outline: none;
  color: white;
  transition: 3s;
}
.img-btn button:nth-child(1):hover {
  border: 1px solid rgba(255, 105, 0, 1);
  background-color: rgba(255, 255, 255, 0.5);
  color: white;
  background: none;
}
.img-btn button:nth-child(2) {
  font-size: 22px;
  padding: 10px 15px;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 5px;
  border-radius: 10px;
  border: none;
  outline: none;
  color: white;
  background: none;
  transition: 3s;
}
.img-btn button:nth-child(2):hover {
  background-color: white;
  color: black;
}
.img-section {
  text-align: start;
  background-color: #FCE5CD;
}
.img-section h1 {
  padding-top: 20px;
  margin-top: 30px;
  font-weight: bold;
  color: red;
  margin-bottom: 20px;
}
.img-text span {
  font-size: 20px;
  line-height: 30px;
  word-spacing: 5px;
}
.img-text button {
  background-color: #4f8fda;
  padding: 10px 15px;
  font-size: 25px;
  border: none;
  text-align: center;
  outline: none;
  border-radius: 20px;
}
.text-Section {
  background-color: #efefef;
  margin: 30px auto;
}
.footerss {
  background-color: black;
  color: white;
  width: 98%;
}
.footerss h6 {
  font-size: 19px;
  margin-bottom: 10px;
}
.footerss i {
  background-color: white;
  color: blue;
  font-size: 22px;
  margin: 5px;
  padding: 5px;
  border-radius: 50%;
}
.footerss-bootom {
  border-top: 1px solid gray;
  padding: 10px 0px;
}
.whatsapp {
  margin: auto;
  width: 70px;
  height: 70px;
  padding: 5px;
  border-radius: 5px;
  color: #58d102;
  font-size: 40px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.whatsapp-text {
  width: 60%;
  text-align: center;
  margin: 20px auto;
  font-size: 20px;
  color: black;
}
.game-text {
  text-align: center;
  margin: 20px auto;
  font-size: 20px;
}
.dragon {
  margin: auto;
  width: 70px;
  height: 70px;
  padding: 5px;
  border-radius: 5px;
  font-size: 40px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  color: #017575;
}
.about1-text h6 {
  text-align: start;
  line-height: 40px;
  margin: 20px 130px;
  padding: 20px;
}
.clint-feedback {
  transition: 2s;
  position: relative;
  top: 0px;
}
.clint-feedback:hover {
  top: -30px;
}
.about-pages {
  width: 90%;
  margin: 40px auto;
}
.about-row {
  background-color: #f3f6fd;
  color: black;
  padding: 10px 0px;
  text-align: center;
}
.about-pages h2 {
  color: #1e73be;
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  font-style: normal;
  margin: 20px 0px;
}
.about-pages p {
  font-size: 20px;
  line-height: 40px;
  text-align: justify;
}
.about-img {
  background-color: #acddde;
  padding: 40px;
  margin: 20px 0px;
}
.about-img img {
  width: 90%;
  text-align: center;
  margin-left: 50px;
  padding: 20px;
}
.about-img button {
  background-color: rgba(255, 105, 0, 1);
  padding: 10px 15px;
  font-size: 20px;
  color: #ffff;
  border-color: #ff7d00;
  border-radius: 10px;
  transition: 2s;
}
.about-img button:hover {
  background-color: rgba(255, 105, 0, 1);
  color: #ffff;
  border-color: #ff7d00;
}
.gaming-page {
  width: 90%;
  margin: auto;
}
.gaming-slider {
  background-color: #e5e4e2;
}
.gaming-slider p {
  line-height: 30px;
  font-size: 18px;
}
.gaming-youtube {
  background-color: #f1de26;
  padding: 10px;
  text-align: center;
}
.gaming-youtube h2 {
  font-weight: bold;
  color: white;
  margin: 20px 0px;
}
.gaming-youtube button {
  background-color: #ff7d00;
  color: white;
  font-size: 20px;
  border-radius: 10px;
  padding: 10px 15px;
  margin: 30px 0px;
}
.gaming-youtube button:hover {
  background-color: white;
  color: #ff7d00;
  border-color: #ff7d00;
}
.gaming-protfolio img {
  width: 250px;
  height: 150px;
  text-align: center;
  margin: auto;
}
.card0 {
  height: 380px !important;
}
.gaming-protfolio .card1 {
  height: 380px !important;
  background-color: #53a0a2;
  color: white;
}
.card5 {
  height: 380px !important;
  background-color: #5e5a85 !important ;
  color: white !important;
}
.card7 {
  height: 380px !important;
  background-color: #90a15e !important;
  color: white !important;
}
.card8 {
  height: 380px !important;
  background-color: #dd6a35 !important;
  color: white !important;
}
.card9 {
  height: 380px !important;
  background-color: #3b8fc8 !important;
}

.finetech-page {
  background-color: #f3f2f2;
}
.finetech-page p {
  padding: 0px 40px;
  font-size: 20px;
  text-align: justify;
}
.finetech-page ul {
  font-weight: normal;
  text-align: justify;
  text-decoration-color: blue;
  font-size: 20px;
  line-height: 30px;
}
.finetech-page-row p {
  font-size: 20px;
  text-align: justify;
  line-height: 35px;
}
.finetech-page-row table {
  width: 100%;
  text-align: center;
}
.finetech-page-row table th {
  border: 1px solid black;
  font-size: 20px;
  padding: 5px 0px;
}
.finetech-page-row table td {
  border: 1px solid black;
  padding: 5px 0px;
  font-size: 20px;
}
.fantesy-page {
  background-color: #e2f3fb;
}
.fantesy-page span {
  color: #ff6600;
  font-size: 35px;
  text-align: justify;
  margin: 30px 0px;
}
.fantesy-page p {
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
}
.fantesy-section2 {
  background-color: #9bc077;
}
.fantesy-section2 p {
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
}
.fantesy-section2 h1 {
  color: #dd3333;
}
.fantesy-section3 {
  background-color: #f7d8ba;
}
.fantesy-section3 p {
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
}
.fantesy-section-4 p {
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
}
.fantesy-section-5 {
  background-color: #eafcff;
  margin: 30px 0px;
}
.fantesy-section-5 img {
  width: 100%;
  height: 100%;
}
.color-page p {
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
}
.color-page ul {
  list-style: number;
  font-size: 20px;
  line-height: 30px;
}
.color-section {
  background-color: #f3e7a7;
  margin: 20px auto;
  text-align: center;
}
.color-section button {
  color: white;
  background-color: #ff7d00;
  margin: 10px auto;
  font-size: 22px;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 15px;
  border: none;
  outline: none;
}
.color-section button:hover {
  color: black;
  border: 3px solid #ff7d00;
  background-color: white;
}
.website-page {
  background-color: #0b45a9;
  padding: 30px 0px;
}
.website-page p {
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  color: #ffff;
  padding: 30px 0px;
}
.website-page button {
  color: white;
  background-color: #ff7d00;
  margin: 20px auto;
  font-size: 25px;
  padding: 15px 20px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 15px;
}
.website-page button:hover {
  color: black;
  border: 3px solid #ff7d00;
  background-color: white;
}
.website-page img {
  width: 85%;
  height: 85%;
}
.website-page1 {
  background-color: #8b3167;
  padding: 30px 0px;
}
.website-page1 p {
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  color: #ffff;
  padding: 30px 0px;
}
.website-page1 button {
  color: white;
  background-color: #ff7d00;
  margin: 20px auto;
  font-size: 25px;
  padding: 15px 20px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 15px;
}
.website-page1 button:hover {
  color: black;
  border: 3px solid #ff7d00;
  background-color: white;
}
.website-page1 img {
  width: 85%;
  height: 85%;
  text-align: right;
}
.website-page3 p {
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  color: black;
  padding: 5px 0px;
}
.website-page4 {
  background-color: #b4e7e2;
}
.website-page4 ul {
  list-style: none;
  text-align: justify;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}
.website-page5 {
  background-color: #edf4f7;
}
.website-page5 p {
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  color: black;
  padding: 5px 0px;
}
.website-icon {
  width: 100px;
  height: 100px;
  text-align: center;
  border-radius: 50%;
  margin: auto;
  background-color: #f4f6fd;
  color: #fd7c00;
  font-size: 50px;
  line-height: 100px;
}
.website-page6 {
  background-color: #f3f2f2;
}
.website-page6 p {
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  padding: 5px 0px;
}
.website-page7 {
  background-color: #f7eeee;
}
.website-page7 p {
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  padding: 5px 0px;
}
.android-page {
  background-color: #e3eeff;
}
.android-page p {
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  padding: 5px 0px;
}
.android-page1 {
  background-color: #883267;
}
.android-page1 p {
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  padding: 5px 0px;
}
.android-page1 button {
  color: white;
  background-color: #ff7d00;
  margin: 20px auto;
  font-size: 25px;
  padding: 15px 20px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 15px;
}
.android-page1 button:hover {
  color: black;
  border: 3px solid #ff7d00;
  background-color: white;
}
.android-page3 p {
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  padding: 5px 0px;
}
.android-page4{
  background-color: #E3EEFF;
}
.android-page4 p{
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  padding: 5px 0px;
}
.android-page4 ul{
  list-style: none;
  text-align: justify;
  font-weight: bold;
  font-size: 22px;
}
.android-page-5{
  background-color:#CAF1DE ;
}
.android-page-5 p{
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  padding: 5px 0px;
}
.android-page-5 ul{
  list-style: none;
  text-align: justify;
  font-weight: bold;
  font-size: 22px;
}
.digital-page{
  background-color:#C9DFEC ;
}
.digital-page p{
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  padding: 5px 0px;
  color: #333333;
}
.digital-page1 p{
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  padding: 5px 0px;
}
.bulkWh{
  color: white;
  background-color: #ff7d00;
  margin: 20px auto;
  font-size: 25px;
  padding: 15px 20px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 15px;
}
.bulkWh:hover {
  color: black;
  border: 3px solid #ff7d00;
  background-color: white;
}
.bulkwhatsapp-page{
  background-color:#075E54;
}
.bulkwhatsapp-page p{
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  padding: 5px 0px;
  color: #333333;
}
.bulkwhatsapp-page1{
  background-color:#F3F2F2;
}
.bulkwhatsapp-page1 p{
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  padding: 5px 0px;
  color: #333333;
}
.bulkIcon{
  color: #6DAB3C;
  font-size: 80px;
  margin: 20px 0px;
}
.bulkPta{
  color: #ff7d00;
}
.bulkPargraph{
  color: #003300 !important;
  font-size: 20px;
  padding: 0px !important;
}
.bulkwhatsapp-page2 p{
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  padding: 5px 0px;
  color: #333333;
}
.bulk-colo{
  background-color: white;
  text-align: center;
  margin: 60px 0px;
  border: 1px solid black;
  transition: 2s;
  position: relative;
  top: 0px;
  left: 0px;
}
.bulk-colo:hover{
  top: -30px;
}
.bulk-colo h5{
  text-align: center;
  border: 1px solid #ff7d00;
  font-size: 20px;
  padding: 5px;
  border-radius: 10px;
  margin: 20px 0px;
}
.bulk-colo h6{
  font-size: 40px;
  
}
.bulk-colo h6 span{
  font-size: 100px;
  vertical-align: top;
}
.bulk-colo p{
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
}
.bulksms{
  background-color: #F3F4F1;
}
.bulksms p{
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  padding: 5px 0px;
}
.bulksms1 p{
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  padding: 5px 0px;
}
.blog-page ul li{
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  padding: 5px 0px;
}
.blog-page ol li{
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  padding: 5px 0px;
}
.blog-page p{
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  padding: 5px 0px;
}
.blog-page a{
  color: black;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
}
.blog-page a:hover{
  color: #ff7d00;
  text-decoration: underline;
}
.cat-item{
  position: relative;
  padding-right: 60px;
  list-style: none;
  margin: 10px 0px;
}
.post_count{
  background-color: blue;
  color: white;
  font-size: 22px;
  text-align: center;
  border-radius: 10px;
  padding: 3px;
  position: absolute;
  top: 0px;
  right: 0px;
}
.errorimg{
  width: 60% !important;
  text-align: center;
  margin: auto;
}
.top-btn{
  position: fixed;
  bottom: 0px;
  right: 20px;
}
.top-btn button{
  font-size: 25px;
  padding: 5px 15px;
  /* background-color: blue; */
  background: none;
  border: none;
  color: #146cba;
}
.whatsappFixed{
  font-size: 60px;
  border: none;
  outline: none;
  height: auto;
  position: fixed;
  left: 10px;
  bottom: 30px;
}
.whatsappFixed button{
  padding: 5px;
  margin: 0px;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0.1);
}
.whatsappFixed button i{
  color:#25D366;
  font-weight: bold;
  padding: 5px;
}
.contact-page p{
  line-height: 30px;
  font-size: 20px;
  text-align: justify;
  padding: 5px 0px;
}
.contact-page h4{
  color: #ff7d00;
}
.contact-page span{
  font-size: 20px;
}
.contact-page form label{
  font-size: 22px;
  padding: 5px;
}
.contact-page form select,textarea,input{
  width: 90%;
  padding: 5px;
  margin: 5px;
  font-size: 22px;
  border-radius: 15px;

}
.contact-page form button{
  padding: 10px 15px;
  border-radius: 20px;
  margin: 10px;
  font-size: 22px;
  font-weight: bold;
}
.about-pages {
  width: 100%;
  margin: 40px auto;
}
.gaming-page {
  width: 100%;
  margin: auto;
}
.finetech-page img {
  width: 90%;
  height: 90%;
  text-align: center;
  margin: auto;
}
.finetech-page-row img {
  width: 90%;
  height: 90%;
  text-align: center;
  margin: auto;
}
.windoShow{
  width: 100%;
  height: 100vh;
  opacity: 99999999;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0px;
  left: 0px;
  transition: 1s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.windoShowss{
  top: -200%;
}
.window-page{
  width: 520px;
  margin: auto;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  /* position: relative;
  top:50%;
  transform: translateY(-50%); */
  
}
.window-page form{
  padding: 20px;
  margin: auto;
 
}
.terms{
  font-size: 20px;
}
.copyright{
  font-size: 20px;
}
.window-page form label{
  font-size: 17px;
  margin:10px 0px ;
  font-weight: bold;
}
.window-page form input{
  width: 93%;
  padding: 5px;
  font-size: 22px;
 display: block;
 /* margin: auto; */
 /* border: 1px solid black; */
 background-color: rgba(152, 158, 175, 0.14);
 border: none;
 border-radius: 5px;
}
.window-page form button{
  appearance: none;
  background-color: green;
  border-radius: 40em;
  border-style: none;
  box-sizing: border-box;
  color:white;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  outline: none;
  quotes: auto;
  text-align: center;
  text-decoration: none;
  transition: all .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.window-page form button:hover {
  background-color: rgb(2, 97, 2);
  transform: scale(1.125);
}

.window-page form button:active {
  transform: scale(1.025);
}
.window-page form h5{
  width: fit-content;
  position: relative;
  bottom: 0px;
  font-size: 25px;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid black;
  cursor: pointer;
}
.window-page form h5:hover{
  background-color: red;
  color: white;
}
/*Counter up Style */

.hero {
  width: 100%;
  height: 800px;
  color: #c4dfe6;
  background-color: #07575b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero h1 {
  font-size: 5rem;
}

.title {
  font-size: 2rem;
  display: flex;
  justify-content: center;
  color: #003b46;
  text-transform: capitalize;
  margin-bottom: 5rem;
}

.title h1 {
  border-bottom: 1px solid #003b46;
}

.counter {
  padding: 5rem 3rem 5rem 3rem;
  background: #c4dfe6;
}

.counter-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.counter-column {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  padding-left: 1rem;
  padding-right: 1rem;
  border-right: 1px solid #003b46;
  position: relative;
}
.icon11 {
  position: absolute;
  top: 10px;
  left: 240px;
}
.icon1 {
  position: absolute;
  top: 8px;
  left: 205px;
}
.counter-column:last-child {
  border-right: none;
}

.counter-column .number {
  font-weight: 600;
  font-size: 50px;
  color: #003b46;
}

.counter-column span {
  display: block;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
  line-height: 1.2;
  padding-left: 15px;
}

.counter-column .percentage {
  font-style: 3rem;
  font-weight: 400;
  color: #003b46;
}

.footer {
  width: 100%;
  height: 500px;
  background-color: #07575b;
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchbtn{
 display: none;   
}
.slideTable{
  width:100%;
  height:45vh;

}
.slideMain1{
  display:flex;
  align-items:center;
  height:50vh
}
.slideMain{
  height:35vh;
  width:100%   
}
@media screen and (max-width: 1024px) {
  .hero h1 {
    font-size: 3rem;
  }
  .counter-row {
    grid-template-columns: 1fr 1fr;
  }
  .counter-column {
    padding: 2rem 0 2rem 0;
    border: none;
  }
  .icon11 {
    top: 39px;
    left: 286px;
  }
  .icon1 {
    top: 39px;
    left: 248px;
  }
}

@media screen and (max-width: 560px) {
  .title {
    font-size: 1.5rem;
    margin-top: 3rem;
  }
  .counter {
    padding: 1rem 1rem 2rem 1rem;
  }
  .counter-row {
    grid-template-columns: 1fr;
  }
  .counter-column {
    padding: 2rem 0 2rem 0;
  }
  .icon11 {
    top: 42px;
    left: 250px;
  }
  .icon1 {
    top: 39px;
    left: 213px;
  }
}


.circle{
  width: 100px;
  height: 100px;
  border: 10px solid gray;
  border-radius: 50%;
  color: black;
  font-size: 70px;
  line-height: 69px;
  text-align: center;
  margin: auto;
}
.circle1{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  transition: 2s;
  top: -100px;
  left: 164px;
  
}
.circle1:hover{
  animation: circle1 2s linear infinite;
  transform: rotate(180deg);
}
.circle-dot{
  position: absolute;
  top: -10px;
  left: 41px;
  background-color: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;

}
.circle-dot2{
  position: absolute;
  bottom: -9px;
  left: 41px;
  background-color: orange;
  width: 20px;
  height: 20px;
  border-radius: 50%;

}
.cardtxt1{
  font-size: 16px;
}
.cardtxt2{
  font-size: 14px;
}
@keyframes circle1{
  0%{
      transform: rotate(180deg);
  }
  100%{
      transform: rotate(360deg);
  }
}
.slideImages{
  width:100%;
  height:40vh
}

@media only screen and (min-width: 200px) and (max-width: 700px) {
  body {
    overflow-x: hidden;
  }
  .header {
    position: fixed;
    top: 0px;
    left: 0px;
    display: none;
  }
  .navbar {
    flex-direction: column;
    background-color: white;
    
  }
  .navbar-header {
    top: -2px;
    left: 0px;
  }
  .navbar-logo {
    width: 55%;
    text-align: center;
  }
  .navbar-center {
    width: 100%;
    position: fixed;
    top: 125px;
    color: white;
    left: -150%;
    transition: 2s;
  }
  .activeraw {
    left: 0px;
    background-color: black;
    text-align: start;
    color: white;
  }
  .hLink {
    color: white;
    
  }
  .btnGet{
    display: flex;
    justify-content: left;
    height: 8vh;
  }
  
  .getTxtLive{
   font-size: 18px;
   border-radius: 5px;

  }
  .navbar-center ul {
    flex-direction: column;
  }
  .navbar-pop {
    display: block;
    top: 6px;
    left: 45px;
    font-size: 25px;
    z-index: 99999;
    background-color: white;
    
  }
  .Popup {
    position: fixed;
    top: 130px;
    left: 20px;
    width: 300px;
    transition: 1s ease-in-out;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
  }
  .activePopup {
    position: fixed;
    top: 0px;
    left: 60px;
    width :300px;
    transition: 1s ease-in-out;
    visibility: visible;
    opacity: 1;
    z-index: 99;
  }
  .navbar-pop span {
    position: fixed;
    top: 12px;
    right: 45px;
    font-size: 20px;

  }
  .navbar-right {
    display: none;
  }
  .img-btn {
    flex-direction: column;
  }
  .about1-text h6 {
    padding: 5px;
    margin: 5px;
  }
  .whatsappFixed{
    font-size: 60px;
    background-color: none;
    border: none;
    outline: none;
    height: 15vh;
    position: fixed;
    left: 5px;
    bottom: 10px;
  }
  .windoShow{
    width: 100%; 
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .window-page form h5{
    left: 200px;
  }
  .window-page{
    width: 100%;
    margin: 0px;
  }
  .top-btn{
    position: fixed;
    visibility: none;
    bottom: 0px;
    right: 424px;
  }
  .bg-img {
    width: 100%;
    height: 40vh;
    background-color: rgba(255, 255, 255, 0.5);
    background-image: url(./image/it_agency_slider_1.webp);
    background-position: center center;
    background-size: cover;
    box-sizing: border-box;
  }
 
  .img-contant{
    padding-top: 40px;
    margin-left: 0px;
  
  }
  .img-contant h1 {
    color: white;
    font-size: 20px;
   
  }
  .img-contant h5 {
    color: white;
    font-size:12px;
   
  }
  .img-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  
  }
  .img-btn button:nth-child(1) {
    background-color: rgba(255, 105, 0, 1);
    font-size: 12px;
    padding: 10px 15px;
    margin: 5px;
    border-radius: 10px;
    border: none;
    outline: none;
    color: white;
    transition: 3s;
  }
  .img-btn button:nth-child(1):hover {
    border: 1px solid rgba(255, 105, 0, 1);
    background-color: rgba(255, 255, 255, 0.5);
    color: white;
    background: none;
  }
  .img-btn button:nth-child(2) {
    font-size: 12px;
    padding: 10px 15px;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 5px;
    border-radius: 10px;
    border: none;
    outline: none;
    color: white;
    background: none;
    transition: 3s;
  }
  .img-btn button:nth-child(2):hover {
    background-color: white;
    color: black;
  }
  .highlight-blue {
    border-radius: 1em 0 1em 0;
    background-image: linear-gradient(
      -100deg,
      rgba(0, 20, 255, 0.2),
      #146cba 95%,
      #6a8398
    );
    color: white;
    width: 80%;
  }
  .highlight-bluewhat{
    border-radius: 1em 0 1em 0;
    background-image: linear-gradient(
      -100deg,
      rgba(0, 20, 255, 0.2),
      #146cba 95%,
      #6a8398
    );
    color: white;
    width: 100%;
    font-size: 22px;
  }
  .terms{
    font-size: 15px;
  }
  .copyright{
    font-size: 12px;
  }
  .thanks{
    position:absolute;
    top:49%;
    left:34%
  }
  .cardtxt1{
    font-size: 14px;
  }
  .cardtxt2{
    font-size: 14px;
  }
  .navbar-center > ul > .service:hover .Popup {
    opacity: 1 !important;
    width: 400px !important;
    z-index: 1 !important;
    visibility : visible !important;
  }
  .navbar-center > ul > .gaming:hover .Popup {
    opacity: 1;
    width: 400px;
    z-index: 1;
    visibility: visible;
  }
  .slideImages{
    width:100%;
    height:30vh
  }
  .slideTable{
    width:100%;
    height:30vh;
    margin-bottom: 12px;
  }
  .slideMain{
    height:28vh;
    width:100% 
  }
  .slideMain1{
    height:30vh
  }
}
  @media only screen and (min-width: 701px) and (max-width: 1024px) {
    body {
      width: 100%;
      box-sizing: border-box;
      margin: auto;
      overflow-x: hidden;
      text-align: center;
    }
    .header {
      position: fixed;
      top: 0px;
      left: 0px;
      display: none;
    }
    .navbar {
      flex-direction: column;
      background-color: white;
    }
    .navbar-header {
      top: -2px;
      left: 0px;
    }
    .navbar-logo {
      width: 90%;
      text-align: center;
    }
    .navbar-center {
      width: 100%;
      position: fixed;
      top: 125px;
      color: white;
      left: -150%;
      transition: 2s;
    }
    .activeraw {
      left: 0px;
      background-color: black;
      text-align: start;
      color: white;
    }
    .hLink {
      color: white;
    }
    .navbar-center ul {
      flex-direction: column;
    }
    .navbar-pop {
      display: block;
      top: 42px;
      left: 158px;
      font-size: 30px;
      z-index: 99999;
      background-color: white;
    }
    .Popup {
      position: fixed;
      top: 130px;
      left: 600px;
      width: 300px;
      transition: 1s ease-in-out;
      visibility: hidden;
      opacity: 0;
      z-index: -1;
    }
    .activePopup {
      position: fixed;
      top: 0px;
      left: 80px;
      width: 100%;
      transition: 1s ease-in-out;
      visibility: visible;
      opacity: 1;
      z-index: 99;
    }
    .navbar-pop span {
      position: fixed;
      top: 42px;
      right: 154px;
      font-size: 25px;
    }
    .navbar-right {
      display: none;
    }
    .img-btn {
      flex-direction: column;
    }
    .about1-text h6 {
      padding: 5px;
      margin: 5px;
    }
    .whatsappFixed{
      font-size: 70px;
      background-color: white;
      border: none;
      outline: none;
      height: auto;
      position: fixed;
      left: 7px;
      bottom: 10px;
    }
    .windoShow{
      width: 100%;
      left: 0px;
      display: flex;
      justify-content: center;
    }
    .window-page form h5{
      left: 200px;
    }
    .window-page{
      width: 100%;
      margin: 0px;
    }
    .top-btn{
      position: fixed;
      bottom: 0px;
      right: 145px;
    }
    .navbar-center > ul > .service:hover .Popup {
      opacity: 1 !important;
      width: 400px !important;
      z-index: 1 !important;
      visibility : visible !important;
    }
    .navbar-center > ul > .gaming:hover .Popup {
  opacity: 1;
  width: 400px;
  z-index: 1;
  visibility: visible;
}
    }


.animate__animated.animate__rollIn {
  --animate-duration: 5s;
}
.animate__animated.animate__rubberBand{
  --animate-duration: 5s;
}

